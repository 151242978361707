// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   apiUrl: 'https://staging.onlineservices.tmede.gr:443/api',
//   clientId: 'TTDHUSE1006',
//   guaranteeUrl: 'https://localhost:3401/#/authorization?token=',
//   loginUrl: 'https://test.gsis.gr/oauth2server/oauth/authorize',
//   logoutURL: 'https://test.gsis.gr/oauth2server/logout/TTDHUSE1006/?url=https://staging.onlineservices.tmede.gr:3301',
//   production: false,
//   proclamationsUrl: 'https://localhost:3201/#/authorization?token=',
//   requestServerUrl: 'http://localhost:3450',
//   showGuarantees: false,
//   showLiveStreaming: true,
//   updateRequestUrl: 'https://localhost:3101/#/authorization?token='
// };

export const environment = {
  apiUrl: 'https://onlineservices.tmede.gr/api',
  clientId: 'L2VIZCP1006',
  guaranteeUrl: 'https://guarantee.onlineservices.tmede.gr/#/authorization?token=',
  loginUrl: 'https://oauth2.gsis.gr/oauth2server/oauth/authorize',
  logoutURL: 'https://oauth2.gsis.gr/oauth2server/logout/L2VIZCP1006/?url=https://onlineservices.tmede.gr',
  production: true,
  proclamationsUrl: 'https://proclamations.onlineservices.tmede.gr/#/authorization?token=',
  requestServerUrl: 'https://api-request.onlineservices.tmede.gr',
  showLiveStreaming: false,
  showGuarantees: false,
  updateRequestUrl: 'https://update.onlineservices.tmede.gr/#/authorization?token='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
