<div *ngIf="show"
     class="backdrop"
     [ngClass]="{'full-screen' : loadingConfig?.fullScreenBackdrop == true}"
     [ngStyle]="{'border-radius': loadingConfig?.backdropBorderRadius, 'background-color': loadingConfig?.backdropBackgroundColour}"></div>

<div *ngIf="show">
  <div class="spinner-three-bounce" [ngClass]="{'full-screen' : loadingConfig?.fullScreenBackdrop == true}">
    <p class="loading-message">Παρακαλώ περιμένετε</p>
    <div class="bounce1" [ngStyle]="{'background-color': loadingConfig?.primaryColour}"></div>
    <div class="bounce2" [ngStyle]="{'background-color': loadingConfig?.secondaryColour}"></div>
    <div class="bounce3" [ngStyle]="{'background-color': loadingConfig?.tertiaryColour}"></div>
  </div>
</div>
