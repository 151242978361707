import * as Sentry from '@sentry/browser';
import {CookieService} from 'ngx-cookie-service';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {LoadingService} from '../shared/services/loading.service';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../environments/environment';

Sentry.init({
  dsn: 'https://89b6bc7abdd44524969ed23b3cd7ba1d@o348931.ingest.sentry.io/2245308'
});

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private cookieService: CookieService,
              private loadingService: LoadingService,
              private matDialog: MatDialog,
              private ngZone: NgZone) {
  }

  handleError(error) {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.log(error);
    }

    if (error.status === 500) {
      this.cookieService.delete('Token');
      window.location.href = `${environment.loginUrl}?client_id=${environment.clientId}&response_type=code&redirect_uri=${environment.apiUrl}/login/callback`;
    } else {
      this.ngZone.run(() => {
        this.loadingService.hide();
        this.matDialog.open(ErrorDialogComponent, {});
      });
    }
  }
}
