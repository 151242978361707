import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {LoadingService} from '../../shared/services/loading.service';
import {environment} from '../../../environments/environment';

@Component({
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {
  public message: string;
  private mode: string;

  constructor(private activatedRoute: ActivatedRoute,
              private loadingService: LoadingService) {
  }

  public ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      this.message = data.message;
      this.mode = data.mode;
      this.loadingService.hide();
    });
  }

  public logout(): void {
    this.loadingService.show();
    window.location.href = `${environment.logoutURL}`;
  }

  public showErrorButton(): boolean {
    return this.mode === 'error';
  }
}
