<div class="container">
  <div class="left-section">
    <div class="logo">
      <img src="../../../assets/images/BlackLogo.png">
    </div>

    <div class="not-found-section">
      <mat-icon>report</mat-icon>

      <h3>{{message}}</h3>

      <button *ngIf="showErrorButton()" mat-raised-button (click)="logout()" class="submit-button">Έξοδος</button>
    </div>

    <div class="bottom-section"></div>
  </div>

  <div class="right-section"></div>
</div>
