import {CanActivate, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggedGuardService implements CanActivate {
  constructor(private cookieService: CookieService,
              private router: Router) {
  }

  canActivate() {
    if (!this.cookieService.get('Token')) {
      return true;
    }
    this.router.navigate(['/home']);
    return false;
  }
}
