import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loading',
  styleUrls: ['./loading.component.scss'],
  templateUrl: 'loading.component.html'
})
export class LoadingComponent {
  @Input() show: boolean | undefined;

  public loadingConfig: any = {
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.5)',
    backdropBorderRadius: '0px',
    fullScreenBackdrop: true,
    primaryColour: 'white',
    secondaryColour: 'white',
    tertiaryColour: 'white'
  };

  constructor() {
  }
}
