import {AppComponent} from './app.component';
import {AppErrorHandler} from './errors/app-error-handler';
import {AppRoutingModule} from './app-routing.module';
import {AuthorizationInterceptor} from './interceptors/authorization.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie-service';
import {ErrorDialogComponent} from './errors/error-dialog/error-dialog.component';
import {ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {LoadingComponent} from './shared/loading/loading.component';
import {MaterialModule} from './shared/material.module';
import {NotFoundPageComponent} from './errors/not-found-page/not-found-page.component';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        ErrorDialogComponent,
        LoadingComponent,
        NotFoundPageComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        MaterialModule
    ],
    providers: [
        CookieService,
        {
            provide: ErrorHandler, useClass: AppErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true
        },
        {
            provide: LocationStrategy, useClass: HashLocationStrategy
        }
    ]
})

export class AppModule {
}
