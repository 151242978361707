import {LoggedGuardService} from './shared/guards/logged-guard.service';
import {NgModule} from '@angular/core';
import {NotFoundPageComponent} from './errors/not-found-page/not-found-page.component';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    loadChildren: () => import('./authorization-page/authorization-page.module').then(m => m.AuthorizationPageModule),
    path: 'authorization'
  },
  {
    canActivate: [LoggedGuardService],
    component: NotFoundPageComponent,
    data: {
      message: 'Δεν μπορείτε να συνδεθείτε με τον εταιρικό σας λογαριασμό.',
      mode: 'error'
    },
    path: 'error'
  },
  {
    loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule),
    path: 'home'
  },
  {
    loadChildren: () => import('./live-streaming-page/live-streaming-page.module').then(m => m.LiveStreamingPageModule),
    path: 'live-streaming'
  },
  {
    component: NotFoundPageComponent,
    data: {
      message: 'Δεν υπάρχει η σελίδα που αναζητήσατε.',
      mode: 'notFound'
    },
    path: '**'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
