import {Component, OnInit} from '@angular/core';
import {LoadingService} from './shared/services/loading.service';
import {NavigationCancel, NavigationError, NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public loading: boolean;

  constructor(private loadingService: LoadingService,
              private router: Router) {
    this.loading = true;

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      }

      if (event instanceof NavigationCancel) {
        this.loading = false;
      }

      if (event instanceof NavigationError) {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    this.loadingService.events.subscribe((event) => {
      setTimeout(() => {
        this.loading = (event === 'show');
      });
    });
  }
}
